import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import scrollToElement from 'scroll-to-element';
import { brandColor } from '../styles/globalStyles';
import HalloweenButton from './halloween_btn';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 1000;
  background-color: ${props => props.scrolled ? 'white' : 'transparent'};
  color: ${props => props.scrolled ? brandColor : 'white'};
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    img {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
  }
`;

const LogoText = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;

const CenterSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${props => props.scrolled ? 'white' : brandColor};
    padding: 20px;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: inherit;
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: inherit;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    scrollToElement(sectionId, {
      offset: -100,
      ease: 'out-expo',
      duration: 1500
    });
    setIsMenuOpen(false);
  };

  return (
    <HeaderWrapper scrolled={scrolled}>
      <Nav>
        <LogoSection>
          <Logo to="/">
            <img 
              src="/images/logo.jpeg" 
              alt="Logo" 
              width="40" 
              height="40" 
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
                transition: 'box-shadow 0.3s ease'
              }}
            />
            <LogoText>CodeDaVinci</LogoText>
          </Logo>
        </LogoSection>
        <CenterSection>
          <HalloweenButton />
        </CenterSection>
        <NavLinks isOpen={isMenuOpen} scrolled={scrolled}>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#servizi';
            } else {
              scrollToSection('#servizi');
            }
          }}>Servizi</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#metodo';
            } else {
              scrollToSection('#metodo');
            }
          }}>Metodo</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#exp';
            } else {
              scrollToSection('#exp');
            }
          }}>Exp</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#portfolio';
            } else {
              scrollToSection('#portfolio');
            }
          }}>Portfolio</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#recensioni';
            } else {
              scrollToSection('#recensioni');
            }
          }}>Recensioni</NavLink>
          <NavLink onClick={() => {
            if (window.location.pathname !== '/') {
              window.location.href = '/#faq';
            } else {
              scrollToSection('#faq');
            }
          }}>FAQ</NavLink>
        </NavLinks>
        <HamburgerButton onClick={toggleMenu}>
          ☰
        </HamburgerButton>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;