import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import ScrollToTop from './components/ScrollTop';
import { GlobalStyle } from './styles/globalStyles';
import Header from './components/header';
import Footer from './components/footer';
import { brandColor, secondaryBrandColor } from './styles/globalStyles';
import Offer from './pages/Offer';  // Importa il nuovo componente Offer

// Lazy load page components
const Home = lazy(() => import('./pages/Home'));
const WebDev = lazy(() => import('./pages/WebDev'));
const Design = lazy(() => import('./pages/Design'));
const MobileApp = lazy(() => import('./pages/MobileApp'));
const SEO = lazy(() => import('./pages/SEO'));
const Performance = lazy(() => import('./pages/Performance'));
const Security = lazy(() => import('./pages/Security'));

// Define the ringing animation
const ringing = keyframes`
   0% { transform: rotate(0deg); }
   1% { transform: rotate(15deg); }
   3% { transform: rotate(-14deg); }
   5% { transform: rotate(17deg); }
   7% { transform: rotate(-16deg); }
   9% { transform: rotate(15deg); }
   11% { transform: rotate(-14deg); }
   13% { transform: rotate(13deg); }
   15% { transform: rotate(-12deg); }
   17% { transform: rotate(11deg); }
   19% { transform: rotate(-10deg); }
   21% { transform: rotate(9deg); }
   23% { transform: rotate(-8deg); }
   25% { transform: rotate(7deg); }
   27% { transform: rotate(-6deg); }
   29% { transform: rotate(5deg); }
   31% { transform: rotate(-4deg); }
   33% { transform: rotate(3deg); }
   35% { transform: rotate(-2deg); }
   37% { transform: rotate(1deg); }
   39% { transform: rotate(-0.5deg); }
   41% { transform: rotate(0.5deg); }
   43% { transform: rotate(0deg); }
   100% { transform: rotate(0deg); }
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  z-index: 1000;
`;

const ActionButton = styled.a`
  width: 60px;
  height: 60px;
  background-color: ${brandColor};
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;

  &:hover {
    background-color: ${secondaryBrandColor};
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
`;

const CallButton = styled(ActionButton)`
  &:hover svg {
    animation: ${ringing} 2s ease infinite;
  }
`;

const WhatsAppButton = styled(ActionButton)`
  background-color: #25D366;  // WhatsApp green color

  &:hover {
    background-color: #128C7E;  // Darker shade of WhatsApp green
  }

  img {
    width: 34px;
    height: 34px;
  }

  &:hover img {
    animation: ${ringing} 2s ease infinite;
  }
`;

const drawLine = keyframes`
  0% { width: 0; }
  100% { width: 80%; }
`;

const EmailButton = styled(ActionButton)`
  perspective: 1000px;
  overflow: hidden;
  
  .envelope {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    transform-style: preserve-3d;
  }

  .envelope-front, .envelope-back, .envelope-top, .letter {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  .envelope-front, .envelope-back, .envelope-top {
    background-color: ${brandColor};
  }

  .envelope-top {
    transform-origin: top;
    transition: transform 0.3s ease;
  }

  .envelope-top svg {
    width: 100%;
    height: 100%;
    stroke-width: 1; // Reduced from 2 to 1 for a thinner line
  }

  .letter {
    background-color: white;
    transform: translateY(100%);
    transition: transform 0.3s ease 0.15s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .letter-line {
    height: 1px;
    background-color: ${brandColor};
    width: 0;
    margin: 3px 0;
  }

  &:hover .envelope-top {
    transform: rotateX(-180deg);
  }

  &:hover .letter {
    transform: translateY(20%);
  }

  &:hover .letter-line {
    animation: ${drawLine} 0.5s ease forwards;
  }

  &:hover .letter-line:nth-child(2) {
    animation-delay: 0.1s;
  }

  &:hover .letter-line:nth-child(3) {
    animation-delay: 0.2s;
    width: 40%; // Half line
  }
`;

const ScrollToTopButton = styled(ActionButton)`
  // Rimuovi le proprietà position, bottom, right, z-index
  // poiché ora fa parte del ButtonContainer
`;

const spin = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
`;

const LogoContainer = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
`;

const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
  }

  &::before {
    border: 5px solid ${brandColor};
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  &::after {
    border: 5px solid ${brandColor}22;
    border-bottom-color: transparent;
    border-left-color: transparent;
    transform: rotate(-45deg);
  }
`;

function App() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(window.pageYOffset > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <AppWrapper>
        <GlobalStyle />
        <Header />
        <MainContent>
          <Suspense fallback={
            <LoaderWrapper>
              <LogoContainer>
                <Logo src="/images/logo.jpeg" alt="Logo" />
                <Spinner />
              </LogoContainer>
            </LoaderWrapper>
          }>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/web-dev" element={<WebDev />} />
              <Route path="/design" element={<Design />} />
              <Route path="/mobile-app" element={<MobileApp />} />
              <Route path="/seo" element={<SEO />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="/security" element={<Security />} />
              <Route path="/offer" element={<Offer />} />  {/* Aggiungi la nuova route per Offer */}
            </Routes>
          </Suspense>
        </MainContent>
        <Footer />
        <ButtonContainer>
          {isVisible && 
            <ScrollToTopButton onClick={scrollToTop} aria-label="Torna su">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M18 15l-6-6-6 6"/>
              </svg>
            </ScrollToTopButton>
          }
          <EmailButton href="mailto:codedavinci.software@gmail.com" aria-label="Invia email">
            <div className="envelope">
              <div className="envelope-back" />
              <div className="envelope-top">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M22 6l-10 7L2 6" />
                </svg>
              </div>
              <div className="letter">
                <div className="letter-line"></div>
                <div className="letter-line"></div>
                <div className="letter-line"></div>
              </div>
            </div>
          </EmailButton>
          <WhatsAppButton 
            href="https://wa.me/393664248926" 
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="Contattaci su WhatsApp"
          >
            <img src="/assets/svg/whatsapp.svg" alt="WhatsApp" />
          </WhatsAppButton>
        </ButtonContainer>
      </AppWrapper>
    </Router>
  );
}

export default App;