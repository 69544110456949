import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const brandColor = '#3a0b3e';
export const secondaryBrandColor = '#1686ea'; // Light blue color from the logo

export const GlobalStyle = createGlobalStyle`
  :root {
    --brand-color: ${brandColor};
    --brand-color-light: #4a0e4e;
    --brand-color-dark: #2a082e;
    --secondary-brand-color: ${secondaryBrandColor};
  }

  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const PageContainer = styled.div`
  // ... stili per il container della pagina
`;

export const PageTitle = styled.h1`
  // ... stili per il titolo della pagina
`;

export const SectionTitle = styled.h2`
  font-size: 2rem;
`;

export const Paragraph = styled.p`
  font-size: 1.2rem;
`;
